import { lazy, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Navigate, Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import { SentryRoutes } from '../utils/sentry';
import { getIsAuthenticated, getIsAuthenticating } from '../redux/auth/selectors';
import { logout } from '../redux/auth';
import logo from '../images/logo.png';
import { Gate } from './guard';
import { getBackgroundProcessing } from '../redux/processing';

const DashboardLayout = lazy(() => import('../layouts/dashboard'));
const PublicLayout = lazy(() => import('../layouts/public'));

const AppBarButton = styled(Button)(({ theme }) => ({
    color: theme.palette.common.white,
    textTransform: 'none',
}));

const App = () => {
    const isAuthenticated = useSelector(getIsAuthenticated);
    const isAuthenticating = useSelector(getIsAuthenticating);
    const isBackgroundProcessing = useSelector(getBackgroundProcessing);
    const dispatch = useDispatch();

    return (
        <Suspense fallback={<div />}>
            <Gate>
                <Container disableGutters maxWidth={false} sx={{ height: '100%' }}>
                    <AppBar component="header" position="static" color="primary">
                        <Toolbar>
                            <Stack direction="row" spacing={2} flex={1} alignItems="center">
                                <img src={logo} alt="Quench logo" width="30px" height="20px" />
                                <Typography variant="h3" marginLeft="10px">
                                    Quench
                                </Typography>
                                {isAuthenticated && (
                                    <AppBarButton color="inherit" component={Link} to="/dashboard">
                                        Cases
                                    </AppBarButton>
                                )}
                            </Stack>

                            {isAuthenticated && (
                                <>
                                    <AppBarButton
                                        color="inherit"
                                        startIcon={<SettingsIcon />}
                                        component={Link}
                                        to="/dashboard/settings"
                                    >
                                        Settings
                                    </AppBarButton>
                                    <AppBarButton
                                        onClick={() => {
                                            dispatch(logout());
                                        }}
                                        color="inherit"
                                        startIcon={<LogoutIcon />}
                                        disabled={isBackgroundProcessing}
                                    >
                                        Logout
                                    </AppBarButton>
                                </>
                            )}
                        </Toolbar>
                    </AppBar>

                    <SentryRoutes>
                        <Route
                            path="/dashboard/*"
                            element={
                                !isAuthenticated && !isAuthenticating ? (
                                    <Navigate replace to="/login" />
                                ) : (
                                    <DashboardLayout />
                                )
                            }
                        />
                        <Route
                            path="*"
                            element={isAuthenticated ? <Navigate replace to="/dashboard" /> : <PublicLayout />}
                        />
                    </SentryRoutes>
                </Container>
            </Gate>
        </Suspense>
    );
};

export default App;
